import { Avatar, Box, Divider, Typography } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from '@mui/icons-material/Close';

export default React.forwardRef(function StandardAEPSReceiptBuilder(
  props,
  ref
) {
  const { payload, user } = props;

  console.log(payload);

  return (
    <Box
      mt={"8px"}
      px={{ xs: 3, sm: 2 }}
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
      }}
      ref={ref}
    >
      <Typography sx={{ fontWeight: "600", textAlign: "center", mb: 1 }}>
        AEPS - {payload.response.serviceType}
      </Typography>
      <Divider />

      <Box
        sx={{
          display: "flex",
          margin: "auto",
          justifyContent: "center",
          pt: 3,
          pb: 2,
        }}
      >
      {
        payload.status === 1 ?
        <Avatar sx={{ backgroundColor: "blue" }}>
          <CheckIcon fontSize="large" />
        </Avatar> :
        <Avatar sx={{ backgroundColor: "red" }}>
          <CloseIcon fontSize="large" />
        </Avatar>
      }
      </Box>

      <Box
        sx={{
          textAlign: "center",
          p: 1,
          mt: 1,
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        {payload.amount} INR
      </Box>

      <KeyValue
        label={"Status"}
        val={payload.status === 1 ? "Successful" : "Failure"}
      />
      <Divider />
      <KeyValue label={"Transaction Date"} val={payload.response.date} />
      <Divider />
      <KeyValue label={"Merchant Name"} val={user.fname + " " + user.lname} />
      <Divider />
      <KeyValue label={"Merchant Mobile"} val={user.mobile} />
      <Divider />
      <KeyValue
        label={"Customer Aadhaar No."}
        val={payload.response.customer_aadhaar_no}
      />
      <Divider />
      <KeyValue
        label={"Customer Mobile No."}
        val={payload.response.customer_mobile}
      />
      <Divider />
      <KeyValue label={"Bank Name"} val={payload.response.bankName} />
      <Divider />
      <KeyValue label={"TID"} val={"#" + payload.response.clientId} />
      <Divider />
      <KeyValue label={"UTR No."} val={"#" + payload.response.utrNo} />
      <Divider />
      <KeyValue
        label={"Customer Balance"}
        val={payload.response.balance + " INR"}
      />
      <KeyValue
        label={"Response"}
        val={
          payload.status === 1 ? "Transaction was Successful" : payload.message
        }
      />
      {payload.response.mini_statement &&
      payload.response.mini_statement.length > 0 ? (
        <Box py={2}>
          <Typography mb={2} variant="h6" textAlign={"center"}>
            Statement
          </Typography>
          <Divider />
          {payload.response.mini_statement.map((t) => (
            <MinStatement txn={t} />
          ))}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
});

function KeyValue(props) {
  const { label, val } = props;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        my: "4px",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography textAlign={"right"} fontSize={"13px"}>
          {label}
        </Typography>
      </Box>
      <Box px={1}>:</Box>
      <Box sx={{ flex: 1 }}>
        <Typography fontSize={"13px"}>
          {val === null || val === "#null" || val === "null INR" || val === ""
            ? "-"
            : val}
        </Typography>
      </Box>
    </Box>
  );
}

function MinStatement(props) {
  const { txn } = props;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: "4px",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography fontSize={"13px"}>{txn.Date}</Typography>
        </Box>

        <Box sx={{ flex: 1 }}>
          <Typography fontSize={"13px"}>{txn.Mode}</Typography>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography fontSize={"13px"} textAlign={"right"}>
            INR {txn.Amount}
          </Typography>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography fontSize={"13px"} textAlign={"right"}>
            {txn.Type === "C" ? "Credit" : "Debit"}
          </Typography>
        </Box>
      </Box>
      <Divider />
    </>
  );
}
