import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import StandardStyledTextfield from "../../../../StandardCustomComponent/StandardStyledTextfield";
import StandardStyledButton from "../../../../StandardCustomComponent/StandardStyledButton";
import FingerprintOutlined from "@mui/icons-material/FingerprintOutlined";
import XMLParser from "react-xml-parser";
import aeps_request from "../../../../../../rest_api/aeps_request";
import { useNavigate } from "react-router-dom";

export default function StandardAepsAgentAuthForm(props) {
  const {
    isDeviceReady,
    captureFinger,
    isProcessing,
    setProcessing,
    AgentAadhaarNo,
    setAgentAadhaarNo,
    CustomerMobile,
    setCustomerMobile,
    latitude,
    longitude,
    open_snackbar,
    snackbar_severity,
    snackbar_message,
    setError,
    setAuth_session_id,
    setAeps_twofactorauth,
    aeps_twofactorauth,
    setIs_aadhar_required,
  } = props;
  const navigate = useNavigate();

  const authenticate = async () => {
    if (
      CustomerMobile &&
      AgentAadhaarNo &&
      CustomerMobile.length === 10 &&
      AgentAadhaarNo.length === 12
    ) {
      try {
        setError("");
        setProcessing(true);
        const res = await captureFinger("Capture");

        var xml = new XMLParser().parseFromString(res.data);

        if (
          xml.children[0].attributes.errInfo === "Success." ||
          xml.children[0].attributes.errCode === "0"
        ) {
          const device_data =
            xml.children[1] === undefined &&
            xml.children[0] !== undefined &&
            xml.children[0].children[0].attributes
              ? xml.children[0].children[0]
              : xml.children[1];

          console.log("Captured response: " + JSON.stringify(device_data));

          //console.log("biometric_rdsVer:" + JSON.stringify(xml.children));
          //console.log("biometric_rdsVer 0 :" + JSON.stringify(xml.children[0]));
          // console.log("biometric_rdsId 1 " + JSON.stringify(xml.children[0].children));
          // console.log("biometric_rdsId child " + JSON.stringify(device_data));
          // console.log("biometric_rdsId: " + JSON.stringify(device_data.attributes.rdsId));
          // console.log("biometric_dpId:" + JSON.stringify(device_data.attributes.dpId));
          // console.log("biometric_mi:" + JSON.stringify(device_data.attributes.mi));

          // console.log(
          //   "1 biometric_srno:" +
          //   JSON.stringify(device_data.children[0])
          // );
          // console.log(
          //   "2 biometric_srno:" +
          //   JSON.stringify(device_data.children[0].children[0])
          // );
          // console.log(
          //   "3 biometric_srno:" +
          //   JSON.stringify(device_data.children[0].children[0].attributes.value)
          // );

          const post_data = {
            pid_data: res.data,
            agentAadhaarNo: AgentAadhaarNo,
            latitude: latitude,
            longitude: longitude,
            customer_mobile: CustomerMobile,
            biometric_srno:
              device_data.children[0] !== undefined
                ? device_data.children[0].children[0].attributes.value
                : xml.children[2].children[0].attributes.value,
            biometric_rdsVer: device_data.attributes.rdsVer,
            biometric_rdsId: device_data.attributes.rdsId,
            biometric_dpId: device_data.attributes.dpId,
            biometric_mi: device_data.attributes.mi,
          };

          const auth_res = await aeps_request.aeps_authentication(post_data);
          // console.log(auth_res);
          console.log("auth_res");
          setProcessing(false);
          if (auth_res) {
            if (auth_res.isLoggedIn) {
              if (auth_res.status === 1) {
                const response = auth_res.response;
                if (response.status === 1) {
                  console.log(JSON.stringify(auth_res));
                  console.log(response.auth_reference_no);
                  setAuth_session_id(response.auth_reference_no);
                  setIs_aadhar_required(!aeps_twofactorauth);
                  setAeps_twofactorauth(true);
                } else {
                  open_snackbar(false);
                  snackbar_severity("error");
                  snackbar_message(response.message);
                  open_snackbar(true);
                }
              } else {
                open_snackbar(false);
                snackbar_severity("error");
                snackbar_message(auth_res.message);
                open_snackbar(true);
              }
            } else {
              open_snackbar(false);
              snackbar_severity("error");
              snackbar_message(auth_res.message);
              open_snackbar(true);
              localStorage.clear();
              navigate("/login");
            }
          } else {
            open_snackbar(false);
            snackbar_severity("error");
            snackbar_message("No response from the server");
            open_snackbar(true);
          }
        } else {
          setProcessing(false);
          setError(xml.children[0].attributes.errInfo);
        }
      } catch (e) {
        open_snackbar(false);
        snackbar_severity("error");
        snackbar_message(e.message);
        open_snackbar(true);
      }
    } else {
      open_snackbar(false);
      snackbar_severity("error");
      snackbar_message("Please enter valid mobile no. and aadhaar no.");
      open_snackbar(true);
    }
  };

  return (
    <Box
      px={3}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
        {aeps_twofactorauth ? "Agent Authentication" : "Daily KYC"}
      </Typography>
      <Box mt={1} width={"100%"}>
        <Typography
          variant="caption"
          sx={{ fontWeight: "600" }}
          component={"div"}
          px={1}
          pt={1}
        >
          Customer Mobile Number
        </Typography>
        <StandardStyledTextfield
          placeholder="Mobile Number"
          fullWidth
          value={CustomerMobile}
          onChange={(e) =>
            e.target.value.length <= 10 ? setCustomerMobile(e.target.value) : {}
          }
        />
      </Box>
      <Box mt={1} width={"100%"}>
        <Typography
          variant="caption"
          sx={{ fontWeight: "600" }}
          component={"div"}
          px={1}
          pt={1}
        >
          Agent Aadhaar Number
        </Typography>
        <StandardStyledTextfield
          placeholder="Agent Aadhaar Number"
          fullWidth
          value={AgentAadhaarNo}
          onChange={(e) =>
            e.target.value.length <= 12 ? setAgentAadhaarNo(e.target.value) : {}
          }
        />
      </Box>
      <Box mt={1} width={"100%"}>
        {isProcessing ? (
          <CircularProgress />
        ) : (
          <StandardStyledButton
            variant="contained"
            customcolor={isDeviceReady && !isProcessing ? "#27751d" : "#999999"}
            customfontcolor={props.appData.buttonFontColor}
            p={"15px 16px"}
            startIcon={<FingerprintOutlined />}
            onClick={() =>
              isDeviceReady && !isProcessing ? authenticate() : {}
            }
          >
            Scan & Proceed
          </StandardStyledButton>
        )}
      </Box>
    </Box>
  );
}
